import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  watch
} from 'vue'
import {
  useStore
} from 'vuex'
import {
  useRouter,
  useRoute
} from 'vue-router'
import {
  courierUseCase,
  courierFileUseCase,
  receiveItemFileUseCase,
  jenisKendaraanUseCase
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import moment from 'moment'
import {
  required, helpers, maxLength, minLength, numeric, email
} from '@vuelidate/validators'
import {
  environment
} from '@/utils'
// start add image camera=============================================================
import { compressImage } from '@/plugins/compressor'
import { takePhoto } from '@/plugins/system/camera'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
// import FileUpload from 'primevue/fileupload'
import VueUploadComponent from 'vue-upload-component'
import { isEmpty, isNull } from 'lodash'
// end add image camera=============================================================

const jenisKtp = 'ktp'
const jenisSertifikat = 'sertifikat'
const jenisStnk = 'stnk'

export default {
  components: {
    // FileUpload,
    FileUpload: VueUploadComponent
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const app = getCurrentInstance()
    const addressRef = ref()
    const store = useStore()
    const Id = ref('') as any
    const dataForm = reactive({
      username: null,
      phone: null,
      password: null,
      courierEmail: null,
      courierName: null,
      placeBirth: null,
      birthDate: '',
      address: null,
      lastEducation: null,
      mariedStatus: null,
      ktp: null,
      transportationType: null,
      statusVaksin: null as any,
      isActive: true,
      isBlocked: false
    })
    const isDisabled = ref(false)
    const submitted = ref(false)
    const showPassword = ref(false)
    const gender = ref('L')
    const genderOption = [
      { name: 'Laki - Laki', value: 'L', key: 'gen1' },
      { name: 'Perempuan', value: 'P', key: 'gen2' }
    ]
    const educationLevelOption = [{
      value: 'S1',
      label: 'D-IV / S-1'
    },
    {
      value: 'SMA',
      label: 'Sekolah Mengah Atas (SMA) atau sederajat'
    },
    {
      value: 'SMP',
      label: 'Sekolah Mengah Pertama (SMP) atau sederajat'
    },
    {
      value: 'SD',
      label: 'Sekolah Dasar (SD) atau sederajat'
    }]
    const mariedStatusOption = [
      {
        value: 'Sudah Menikah',
        label: 'Menikah'
      },
      {
        value: 'Belum Menikah',
        label: 'Belum Menikah'
      },
    ]
    const statusVaksinOption = [{
      value: 1,
      label: 'Vaksinasi 1'
    }, {
      value: 2,
      label: 'Vaksinasi 2'
    }, {
      value: 3,
      label: 'Vaksinasi 3'
    }]
    const transportationTypeOption = ref([])
    const courierDocuments = ref([]) as any
    const validPassword = (val: any) => {
      // console.log(val.length)
      if (isNull(dataForm.password)) {
        return true
      }
      const regex = new RegExp('^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      // console.log(regex.test(val))
      return regex.test(val)
    }
    // const courierDocuments = [{
    //   Jenis: 'ktp',
    //   Path: 'media/ktp/2022/jpg/1a3pomjdlbr.jpg'
    // },
    // {
    //   Jenis: 'stnk',
    //   Path: 'media/stnk/2022/jpg/nfqchvmzrez.jpg'
    // },
    // {
    //   Jenis: 'sertifikat',
    //   Path: 'media/sertifikat/2022/jpeg/5py3lucns5i.jpeg'
    // }] as any
    // let rules
    const rules = reactive({}) as any
    if (route.params.courierId) {
      console.log('aaa', route.params.courierId)
      rules.value = {
        phone: {
          required: helpers.withMessage('No. Telp harus diisi', required),
          numeric: helpers.withMessage('No. Telp harus berisi angka', numeric),
          maxLength: helpers.withMessage('No. Telp harus berisi maksimal 13 digit', maxLength(13)),
          minLength: helpers.withMessage('No. Telp harus berisi minimal 11 digit', minLength(11)),
          validPhone: helpers.withMessage('No. Telp harus diawali dengan angka 0', helpers.regex(/^0\d{0,13}$/))
        },
        password: {
          // required: helpers.withMessage('Password harus diisi', required),
          validPassword: helpers.withMessage('Password harus terdiri dari minimal 1 huruf, 1 angka dan 1 karakter spesial', validPassword),
          // minLength: helpers.withMessage('Password minimal mengandung 8 karakter.', minLength(8))
        } as any,
        courierEmail: {
          required: helpers.withMessage('Email harus diisi', required),
          email: helpers.withMessage('Email harus berupa alamat email yang benar.', email)
        },
        courierName: {
          required: helpers.withMessage('Nama harus diisi', required)
        },
        placeBirth: {
          required: helpers.withMessage('Tempat Lahir harus diisi', required)
        },
        lastEducation: {
          required: helpers.withMessage('Pendidikan Terakhir harus diisi', required)
        },
        birthDate: {
          required: helpers.withMessage('Tanggal Lahir harus diisi', required)
        },
        address: {
          required: helpers.withMessage('Alamat Lengkap harus diisi', required)
        },
        mariedStatus: {
          required: helpers.withMessage('Status Pernikahan harus diisi', required)
        },
        ktp: {
          required: helpers.withMessage('No. KTP / SIM harus diisi', required),
          maxLength: helpers.withMessage('No. KTP / SIM harus berisi maksimal 16 digit', maxLength(16)),
          minLength: helpers.withMessage('No. KTP / SIM harus berisi minimal 16 digit', minLength(16)),
          numeric: helpers.withMessage('No. KTP / SIM harus berisi angka', numeric)
        },
        transportationType: {
          required: helpers.withMessage('Jenis Kendaraan harus diisi', required)
        },
        statusVaksin: {
          required: helpers.withMessage('Covid-19 Vaksin harus diisi', required)
        },
      }
    } else if (isNull(dataForm.password)) {
      console.log('bbb', route.params.courierId)
      rules.value = {
        phone: {
          required: helpers.withMessage('No. Telp harus diisi', required),
          numeric: helpers.withMessage('No. Telp harus berisi angka', numeric),
          maxLength: helpers.withMessage('No. Telp harus berisi maksimal 13 digit', maxLength(13)),
          minLength: helpers.withMessage('No. Telp harus berisi minimal 11 digit', minLength(11)),
          validPhone: helpers.withMessage('No. Telp harus diawali dengan angka 0', helpers.regex(/^0\d{0,13}$/))
        },
        password: {
          required: helpers.withMessage('Password harus diisi', required),
          validPassword: helpers.withMessage('Password harus terdiri dari 8 karakter dengan minimal 1 huruf, 1 angka dan 1 karakter spesial', helpers.regex(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}?$/)),
          // minLength: helpers.withMessage('Password minimal mengandung 8 karakter.', minLength(8))
        } as any,
        courierEmail: {
          required: helpers.withMessage('Email harus diisi', required),
          email: helpers.withMessage('Email harus berupa alamat email yang benar.', email)
        },
        courierName: {
          required: helpers.withMessage('Nama harus diisi', required)
        },
        placeBirth: {
          required: helpers.withMessage('Tempat Lahir harus diisi', required)
        },
        lastEducation: {
          required: helpers.withMessage('Pendidikan Terakhir harus diisi', required)
        },
        birthDate: {
          required: helpers.withMessage('Tanggal Lahir harus diisi', required)
        },
        address: {
          required: helpers.withMessage('Alamat Lengkap harus diisi', required)
        },
        mariedStatus: {
          required: helpers.withMessage('Status Pernikahan harus diisi', required)
        },
        ktp: {
          required: helpers.withMessage('No. KTP / SIM harus diisi', required),
          maxLength: helpers.withMessage('No. KTP / SIM harus berisi maksimal 16 digit', maxLength(16)),
          minLength: helpers.withMessage('No. KTP / SIM harus berisi minimal 16 digit', minLength(16)),
          numeric: helpers.withMessage('No. KTP / SIM harus berisi angka', numeric)
        },
        transportationType: {
          required: helpers.withMessage('Jenis Kendaraan harus diisi', required)
        },
        statusVaksin: {
          required: helpers.withMessage('Covid-19 Vaksin harus diisi', required)
        },
      }
    } else {
      console.log('ccc', route.params)
      rules.value = {
        phone: {
          required: helpers.withMessage('No. Telp harus diisi', required),
          maxLength: helpers.withMessage('No. Telp harus berisi maksimal 13 digit', maxLength(13)),
          minLength: helpers.withMessage('No. Telp harus berisi minimal 11 digit', minLength(11)),
          numeric: helpers.withMessage('No. Telp harus berisi angka', numeric),
          validPhone: helpers.withMessage('No. Telp harus diawali dengan angka 0', helpers.regex(/^0\d{0,13}$/))
        },
        password: {
          required: helpers.withMessage('Password harus diisi', required),
          validPassword: helpers.withMessage('Password harus terdiri dari minimal 1 huruf, 1 angka dan 1 karakter spesial', validPassword),
          minLength: helpers.withMessage('Password minimal mengandung 8 karakter.', minLength(8))
        } as any,
        courierEmail: {
          required: helpers.withMessage('Email harus diisi', required),
          email: helpers.withMessage('Email harus berupa alamat email yang benar.', email)
        },
        courierName: {
          required: helpers.withMessage('Nama harus diisi', required)
        },
        placeBirth: {
          required: helpers.withMessage('Tempat Lahir harus diisi', required)
        },
        lastEducation: {
          required: helpers.withMessage('Pendidikan Terakhir harus diisi', required)
        },
        birthDate: {
          required: helpers.withMessage('Tanggal Lahir harus diisi', required)
        },
        address: {
          required: helpers.withMessage('Alamat Lengkap harus diisi', required)
        },
        mariedStatus: {
          required: helpers.withMessage('Status Pernikahan harus diisi', required)
        },
        ktp: {
          required: helpers.withMessage('No. KTP / SIM harus diisi', required),
          maxLength: helpers.withMessage('No. KTP / SIM harus berisi maksimal 16 digit', maxLength(16)),
          minLength: helpers.withMessage('No. KTP / SIM harus berisi minimal 16 digit', minLength(16)),
          numeric: helpers.withMessage('No. KTP / SIM harus berisi angka', numeric)
        },
        transportationType: {
          required: helpers.withMessage('Jenis Kendaraan harus diisi', required)
        },
        statusVaksin: {
          required: helpers.withMessage('Covid-19 Vaksin harus diisi', required)
        },
      }
    }
    const v$ = useVuelidate(rules.value, dataForm)

    const redirectBack = () => {
      router.go(-1)
    }
    const {
      $confirm,
      $toast,
      $strInd,
      $convertPhoneNumber
    } = app!.appContext.config.globalProperties

    // start image camera=============================================================
    const listImage = ref([]) as any
    const checkSrcListImage = () => listImage.value.filter((image: any) => image.src === null).length
    const isDragActive = ref(false)
    const uploadKtp = ref({
      src: null,
      name: null
    }) as any
    const uploadStnk = ref({
      src: null,
      name: null
    }) as any
    const uploadSertifikat = ref({
      src: null,
      name: null
    }) as any
    const tempUploadKtp = ref(null) as any
    const tempUploadStnk = ref(null) as any
    const tempUploadSertifikat = ref(null) as any
    const refUploadStnk = ref()
    const refUploadSertifikat = ref()
    const generateListImage = () => {
      for (let index = 0; index < 5; index++) {
        listImage.value.push({
          name: 'add-image',
          src: null,
          image: null
        })
      }
    }

    const pushListImage = async (item: any, from: any = '') => {
      // console.log('ok from ', from)
      let file = item
      if (from === 'camera') {
        // file = new File([item], 'Name', {
        //   lastModified: moment().unix(),
        //   type: item.type,
        // })
        item = item.image
      } else {
        // eslint-disable-next-line no-await-in-loop
        file = await compressImage(file)
        file = new File([file], file.name)
        console.log('file to compress', file)
      }
      if (from === 'uploadStnk') {
        uploadStnk.value.src = URL.createObjectURL(item)
        uploadStnk.value.image = file
      }
      if (from === 'uploadKtp') {
        uploadKtp.value.src = URL.createObjectURL(item)
        uploadKtp.value.image = file
      }
      if (from === 'uploadSertifikat') {
        uploadSertifikat.value.src = URL.createObjectURL(item)
        uploadSertifikat.value.image = file
      }
      // for (let index = 0; index < listImage.value.length; index++) {
      //   const image = listImage.value[index]
      //   if (image.src === null) {
      //     listImage.value[index].src = URL.createObjectURL(item)
      //     listImage.value[index].image = file
      //     break
      //   }
      // }
    }

    const onUploadStnk = (newFile: any, oldFile: any) => {
      pushListImage(newFile.file, 'uploadStnk')
    }
    const onUploadSertifikat = (newFile: any, oldFile: any) => {
      pushListImage(newFile.file, 'uploadSertifikat')
    }
    const onUploadKtp = (newFile: any, oldFile: any) => {
      pushListImage(newFile.file, 'uploadKtp')
    }

    const removeImage = (from: any) => {
      if (from === 'uploadStnk') {
        uploadStnk.value.src = null
        uploadStnk.value.image = null
      }
      if (from === 'uploadKtp') {
        uploadKtp.value.src = null
        uploadKtp.value.image = null
      }
      if (from === 'uploadSertifikat') {
        uploadSertifikat.value.src = null
        uploadSertifikat.value.image = null
      }
    }

    const showCamera = async () => {
      const response = await takePhoto() as any
      console.log('response', JSON.stringify(response))
      pushListImage(response, 'camera')
    }

    const proccessUploadFile = async (senderId: any) => {
      const listPromise = []
      for (let index = 0; index < listImage.value.length; index++) {
        const item = listImage.value[index]
        if (item.src !== null) {
          const formData = new FormData()
          // const senderId = props.id
          // const senderId = Id
          const img = item.image
          formData.append('KurirId', senderId)
          // formData.append('IsPenerimaan', 'false')
          if (img.from === 'camera') {
            console.log('if on camera')
            formData.append('Photo', img.image, `${img.name}.${img.ext}`)
          } else {
            console.log('img', img)
            formData.append('Photo', img)
          }
          // listPromise.push(courierFileUseCase.submitData(receive.TransPengirimanHd.Id, formData))
          listPromise.push(courierFileUseCase.submitData('', formData))
        }
      }

      await courierFileUseCase.submitDataAll(listPromise).then((responses) => {
        $toast.add({
          severity: 'success', detail: 'Berhasil upload gambar', group: 'bc', life: 1500
        })
        // hideForm()
        // emit('successBack')
        // router.push({ name: 'transaksi-barang-diterima' })
      }).catch(() => {
        $toast.add({
          severity: 'error', detail: 'Gagal upload gambar, silahkan coba kembali', group: 'bc', life: 1500
        })
      })
    }
    const filterListDocuments = (list: any, tipe: any) => list.filter((x: any) => x.Jenis === tipe)

    const getDataEdit = async () => {
      store.dispatch('showLoading')
      const response = await courierUseCase.getProfileCourier(Id.value) as any
      const data = response.result
      isDisabled.value = !route.params.courierId
      dataForm.password = null
      dataForm.username = data.Username
      dataForm.isActive = data.IsActive
      dataForm.phone = data.PhoneNumber
      gender.value = data.Gender
      dataForm.lastEducation = data.LastEducation
      dataForm.birthDate = moment(data.BirthDate).format('DD-MM-YYYY')
      dataForm.placeBirth = data.BirthPlace
      dataForm.mariedStatus = data.StatusPernikahan
      tempUploadKtp.value = filterListDocuments(data.Documents, jenisKtp).length > 0 ? `${environment.getApiBase()}${filterListDocuments(data.Documents, jenisKtp)[0].Path}` : null
      tempUploadSertifikat.value = filterListDocuments(data.Documents, jenisSertifikat).length > 0 ? `${environment.getApiBase()}${filterListDocuments(data.Documents, jenisSertifikat)[0].Path}` : null
      tempUploadStnk.value = filterListDocuments(data.Documents, jenisStnk).length > 0 ? `${environment.getApiBase()}${filterListDocuments(data.Documents, jenisStnk)[0].Path}` : null
      // dataForm.LevelVaksinasi = !isEmpty(data.LevelVaksinasi) ? data.LevelVaksinasi.split(',') : []
      dataForm.ktp = data.IdNumber
      dataForm.transportationType = data?.JenisKendaraan?.Id
      dataForm.statusVaksin = !isEmpty(data.LevelVaksinasi) ? data.LevelVaksinasi.split(',') : []
      // dataForm.Password = data.Password
      // // dataForm.Email = data.Photo
      // dataForm.Username = data.Username
      dataForm.courierName = data.Fullname
      dataForm.courierEmail = data.Email
      dataForm.isBlocked = data.IsBlocked
      // dataForm.IdNumber = data.Attribute1
      // dataForm.isActive = data.IsActive
      // dataForm.vehicleNumber = data.Kendaraans.length > 0 ? data.Kendaraans[0].PlatNomor : null
      dataForm.address = data.Addresses.length > 0 ? data.Addresses[0]?.Alamat1 : null
      // data.KurirMsUserDocuments.map((test: any, index: any) => {
      //   console.log('test', test)
      //   listImage.value[index].src = `${process.env.VUE_APP_API_BASE}${test.Path}`
      //   // listImage.value[index].image = `${process.env.VUE_APP_API_BASE}${test.Path}`
      //   return listImage
      // })
      store.dispatch('hideLoading')
    }
    // end image camera ============================================
    const checkResponse = async (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
        courierDocuments.value = []
      } else {
        console.log('response', response)
        if (Id.value) {
          $toast.add({
            severity: 'success',
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        }
        redirectBack()
      }
    }

    const toAgentId = ref(store.getters['appActiveUser/getAgent']?.id)
    const processUploadFile = async () => {
      if (await uploadKtp.value.src !== null) {
        const formDataKtp = new FormData()
        formDataKtp.append('file', uploadKtp.value.image)

        await receiveItemFileUseCase.submitFile(jenisKtp, formDataKtp).then((res) => {
          // console.log('hasil upload res', res)
          const sendDataFile = {
            Path: res.result.Filename,
            Jenis: jenisKtp,
          } as any
          courierDocuments.value.push(sendDataFile)
        })
      }

      if (await uploadSertifikat.value.src !== null) {
        const formDataSertifikat = new FormData()
        formDataSertifikat.append('file', uploadSertifikat.value.image)
        await receiveItemFileUseCase.submitFile(jenisSertifikat, formDataSertifikat).then((res) => {
          // console.log('hasil upload res', res)
          const sendDataFile = {
            Path: res.result.Filename,
            Jenis: jenisSertifikat,
          } as any
          courierDocuments.value.push(sendDataFile)
        })
      }

      if (await uploadStnk.value.src !== null) {
        const formDataStnk = new FormData()
        formDataStnk.append('file', uploadStnk.value.image)
        await receiveItemFileUseCase.submitFile(jenisStnk, formDataStnk).then((res) => {
          // console.log('hasil upload res', res)
          const sendDataFile = {
            Path: res.result.Filename,
            Jenis: jenisStnk,
          } as any
          courierDocuments.value.push(sendDataFile)
        })
      }
    }

    const submitProccess = async () => {
      store.dispatch('showLoading')
      await processUploadFile()
      // console.log('courier', courierDocuments.value)
      if (courierDocuments.value.length === 3 || Id.value) {
        const data = {
          // IdAuth: 'xxx?',
          Password: dataForm.password === '' ? null : dataForm.password,
          Fullname: dataForm.courierName,
          Email: dataForm.courierEmail,
          MsAgenId: toAgentId.value,
          PhoneNumber: dataForm.phone,
          IsActive: dataForm.isActive,
          Gender: gender.value,
          LastEducation: dataForm.lastEducation,
          BirthDate: moment(dataForm.birthDate, 'DD-MM-YYYY'),
          IdNumber: dataForm.ktp,
          BirthPlace: dataForm.placeBirth,
          StatusPernikahan: dataForm.mariedStatus,
          KurirMsJenisKendaraanId: dataForm.transportationType,
          LevelVaksinasi: dataForm.statusVaksin.toString(),
          Address: dataForm.address,
          KurirMsUserDocuments: courierDocuments.value
          // Jaminan: dataForm.Jaminan,
          // Photo: 'xxxx',
          // Username: dataForm.Username,
          // Attribute1: dataForm.IdNumber, // alamat
          // Attribute2: dataForm.vehicleNumber,
        }
        console.log('ok udah masuk ', data)
        await courierUseCase.submitDataForm(Id.value, dataForm.username, data).then((response: any) => {
          checkResponse(response)
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Pastikan semua foto sudah di upload',
          group: 'bc',
          life: 3000
        })
      }
      store.dispatch('hideLoading')
    }
    const submitData = (isFormValid: boolean) => {
      console.log('isvalid', isFormValid)
      console.log('isvalid', isNull(dataForm.password))
      console.log('isvalid', v$.value.password.$invalid)
      v$.value.password.$invalid = false
      console.log('isvalid', v$.value.password.$invalid)
      submitted.value = true
      // if (isNull(dataForm.password)) {
      //   if (!isFormValid && v$.v)
      // }
      if (!isFormValid) {
        return
      }
      submitProccess()
    }
    const deleteData = () => {
      $confirm.require({
        header: 'Hapus Kurir',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await courierUseCase.deleteData(Id.value)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: $strInd.toast.successDeleteData,
              group: 'bc',
              life: 3000
            })
            redirectBack()
          }
        }
      })
    }

    const blockedCourier = () => {
      $confirm.require({
        header: `${dataForm.isBlocked ? 'Buka Blokir' : 'Blokir'} Kurir`,
        message: `Apakah anda yakin akan ${dataForm.isBlocked ? 'buka blokir' : 'memblokir'} kurir ?`,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await courierUseCase.changeIsBlocked({ Id: Id.value, IsBlocked: !dataForm.isBlocked })
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: response.message,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            redirectBack()
          }
        }
      })
    }

    const setResultAddress = (result: any) => {
      dataForm.address = result
    }

    const getTransportationType = () => {
      jenisKendaraanUseCase.getAll({
        select: '&$select=Id,Kapasitas,Kode,Nama'
      }).then((response: any) => {
        transportationTypeOption.value = response.result.map((x: any) => ({
          label: x.Nama,
          value: x.Id
        }))
      })
    }

    onMounted(() => {
      getTransportationType()
      if (route.params.courierId) {
        Id.value = encryptDecriptMethods.decrypt(route.params.courierId)
      }
      if (Id.value) {
        getDataEdit()
      }
      generateListImage()
      console.log(store.state)
    })

    return {
      Id,
      educationLevelOption,
      mariedStatusOption,
      // Email,
      gender,
      genderOption,
      redirectBack,
      router,
      app,
      submitProccess,
      store,
      checkResponse,
      route,
      getDataEdit,
      deleteData,
      setResultAddress,
      addressRef,
      dataForm,
      v$,
      submitted,
      rules,
      submitData,
      showPassword,

      checkSrcListImage,
      showCamera,
      listImage,
      isDragActive,
      uploadKtp,
      uploadStnk,
      proccessUploadFile,
      uploadSertifikat,
      onUploadSertifikat,
      onUploadStnk,
      refUploadSertifikat,
      refUploadStnk,
      onUploadKtp,
      removeImage,
      statusVaksinOption,
      transportationTypeOption,
      courierDocuments,
      isDisabled,
      tempUploadKtp,
      tempUploadSertifikat,
      tempUploadStnk,
      blockedCourier
    }
  }
}
